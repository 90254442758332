<script setup>
import { defineEmits } from "vue"
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })
const emit = defineEmits(['hideModal'])
const hideModal = () => {
  emit('hideModal')
}
</script>

<template>
  <div class="confirm__modal">
    <div class="confirm__modal-body">
      <div class="modal-content">
        <div class="d-flex justify-content-between align-items-center p-3 mb-4">
          <h5 class="req_title">{{t('text.rese-success-title')}}</h5>
          <svg class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="hideModal" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 2.05625L18.885 0L10.5 8.15208L2.115 0L0 2.05625L8.385 10.2083L0 18.3604L2.115 20.4167L10.5 12.2646L18.885 20.4167L21 18.3604L12.615 10.2083L21 2.05625Z" fill="black" />
          </svg>

        </div>
        <div class="modal-body text-center">
          <div class="check__icon">
            <svg width="123" height="113" viewBox="0 0 123 113" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1305_481)">
                <path d="M61.5 112.963C95.4655 112.963 123 87.6836 123 56.5C123 25.3164 95.4655 0.0370789 61.5 0.0370789C27.5345 0.0370789 0 25.3164 0 56.5C0 87.6836 27.5345 112.963 61.5 112.963Z" fill="#46B276"/>
                <path d="M56.5684 86.5333L27.4905 65.7219L35.7584 55.9738L53.6145 68.7534L83.0846 29.6855L94.143 36.7232L56.5684 86.5333Z" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_1305_481">
                  <rect width="123" height="113" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div>
            <p class="mb-2 fw-500">{{t('text.rese-success-text-1')}}</p>
            <p class="mb-5 fw-500">{{t('text.rese-success-text-2')}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.confirm__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  z-index: 999999999999999999;
}

.confirm__modal-body {
  width: 600px;
  background-color: #fff;
  border-radius: 0.25rem;
  .req_title{
    font-size: 40px; 
    font-weight: 700;
  }
  p{
    color: #4F4444;
  }
}

.check__icon {
  width: 60px;
  height: 60px;
  margin: 1rem auto;
  margin-top: 0;
}
.check__icon svg {
  width: 100%;
  height: 100%;
}
</style>