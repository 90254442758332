<template>

  <div class="container">
    <div class="not-found">
      <img src="@/assets/images/404.png" alt="" srcset="" />
    </div>
  </div>

</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped>
.not-found {
  width: 50%;
  height: auto;
  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
  }
}
</style>
