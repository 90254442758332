export default {
  "date-format": {
    "days": {
      "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SU"])},
      "mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MO"])},
      "tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TU"])},
      "wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WE"])},
      "thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TH"])},
      "fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
      "sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA"])}
    },
    "months": {
      "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["january"])},
      "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["february"])},
      "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["march"])},
      "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["april"])},
      "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["may"])},
      "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["june"])},
      "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["july"])},
      "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["august"])},
      "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["september"])},
      "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["october"])},
      "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["november"])},
      "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["december"])}
    }
  },
  "form-fields": {
    "opening-times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openning Times"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "please-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Select"])}
  },
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleet"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])}
  },
  "footer": {
    "widget-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
    "widget-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cars"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])},
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
    "privecy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])}
  },
  "home-hero": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Roadstars your first class car rental in Berlin"])}
  },
  "home-about": {
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABOUT US"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover Roadstars"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versatile and individual, just like your needs - our selection of vehicles will get you from A to B in a flexible way. Roadstars Car Rental, based in Berlin-Charlottenburg, always offers the right vehicle for your occasion - when and how you want it. Whether small car, middle class or luxury class, we are always available to advise you on the choice of the right vehicle."])}
  },
  "home-cars": {
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONLY THE BEST CARS"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Fleet"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide our customers with the most incredible driving emotions. Thats why we have the best cars in our fleet."])}
  },
  "home-sponsor": {
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IN COOPERATION WITH"])}
  },
  "home-features": {
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAKING CARE OF EVERY CLIENT"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key Features"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are all about our clients comfort and safety. Thats why we provide the best service you can imagine."])}
  },
  "home-contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get In Touch!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening hours"])},
    "hours-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MO-FR  10am - 6pm"])},
    "hours-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA 10am - 4pm"])}
  },
  "home-search-form": {
    "vehicle-class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your vehicle class"])},
    "pick-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick-up location"])},
    "request-botton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request now"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "pickup-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Pick-up Time"])},
    "return-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Return Time"])},
    "pickup-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Pick-up Date"])},
    "return-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Return Date"])},
    "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick-Up Date"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Date"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Dates"])},
    "button-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Time"])}
  },
  "home-booking-request-form": {
    "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])},
    "name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title *"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request reservation"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "pickup-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick up location"])},
    "pickup-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick-up time"])},
    "return-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return time"])},
    "vehicle-class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your vehicle class"])},
    "form-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Details"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "date-of-birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "mrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mrs."])},
    "mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr."])},
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name *"])},
    "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name *"])},
    "date-birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth *"])},
    "place-birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of birth *"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address *"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street & No.*"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City *"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZIP *"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country *"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone *"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email *"])},
    "id-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID No. *"])},
    "driving-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drivers license No. *"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Request"])}
  },
  "button": {
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "cancle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancle"])},
    "please-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Wait"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "legal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
    "managing-director": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing Director"])}
  },
  "profile": {
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])}
  },
  "privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General notice and mandatory information"])},
    "paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designation of the responsible entity"])},
    "paragraph-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The responsible entity for data processing on this website is:"])},
    "paragraph-4": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadstars GmbH"])},
      "road": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahmad El Noumeiri"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaiser-Friedrich-Straße 65"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10627 Berlin"])}
    },
    "paragraph-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The controller alone or jointly with others determines the purposes and means of the processing of personal data (e.g. names, contact details or similar)."])},
    "paragraph-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revocation of your consent to data processing"])},
    "paragraph-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some data processing operations are only possible with your express consent. A revocation of your already give consent is possible at any time. An informal communication by e-mail is sufficient for the revocation. The legality of the data processin revocation remains unaffected by the revocation."])},
    "paragraph-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right to data portability"])},
    "paragraph-9": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a data subject, you have the right to lodge a complaint with the competen supervisory authority. The competent supervisory authority with regard to questions of data protection law is the state data protection commissione of the federal state in which our companys registered office is located. The following link provides a list of th data protection officers and their contact details:"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/addresses_links-node.html"])},
      "link-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["click here"])}
    },
    "paragraph-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right to data portability"])},
    "paragraph-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have the right to transfer data that we process automatically on the basis of your consent or in performance of a contract,or to have it handed over to third parties. The data will be provided in a machine-readable format. If you request the direc transfer of the data to another responsible party, this will only be done insofar as it is technically feasible."])},
    "paragraph-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right to information, correction, blocking, deletion"])},
    "paragraph-13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Within the framework of the applicable legal provisions, you have the right at any time to free information about you stored personal data, origin of the data, their recipients and the purpose of data processing and, if applicable, right to correction, blocking or deletion of this data. In this regard and also for further questions on the subject or personal data, you can contact us at any time via the contact options listed in the imprint."])},
    "paragraph-14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSSL or TLS encryption"])},
    "paragraph-15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For security reasons and to protect the transmission of confidential content that you send to us as the site operator, our website uses a our website uses SSL or TLS encryption. This means that data you transmit via this website cannot be read by third parties third parties. You can recognize an encrypted connection by the 'https://' address line of your browser and the lock symbol in the browser line."])}
  },
  "terms": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions"])},
    "text-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadstars GmbH provides the Lessee with the vehicle according to the described rental conditions and the following General Conditions of Contract, which the Lessee accepts. The rental contract is concluded by written confirmation of the order to the Lessee. If the vehicle is booked via our telephone hotline, the contract is concluded by a confirmation email to the lessee."])},
    "text-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I GENERAL"])},
    "text-p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a) With the entry into force of the contract, the lessee acknowledges that he has taken over the rental car with complete equipment including tools, first aid kit and radio, as well as without any externally visible damage (deviations will be recorded in the rental contract on site). Furthermore, the lessee acknowledges that a confirmed copy of the car documents have been properly handed over to him."])},
    "text-p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b) The lessee can withdraw from the contract by e-mail up to 5 days before the beginning of the rental period, without charging any costs. In case of withdrawal after this time, notified at least 24 hours before the beginning of the rental period, 50% of the rental fee will be charged. In case of withdrawal after this time, as well as in case of non-collection of the vehicle, the entire rental amount will be charged. The lessee is only entitled to a refund of the rental fee in case of an unforeseeable or non-culpable event, such as illness, for which the lessor is not responsible."])},
    "text-p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c) The lessee is liable for all fines, fees and penalties himself. A handling fee of EUR 9.55 per individual case will be charged for the processing of any fee notices incurred."])},
    "text-p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d) The Lessor is entitled to reclaim the vehicle prematurely in the event of significant violations by the Lessee of provisions of this contract or for any other important reason. The Lessee may only offset this by means of an undisputed or legally established claim."])},
    "text-p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e) The lessee declares that, among other risks, he does not drive the vehicle under the influence of drugs or alcohol. It is prohibited to use the vehicle for motor sport events & competitions of any kind (see §VI)."])},
    "text-p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f) Should the lessor have discrepancies in the person of the lessee or should he realize that the lessee cannot fulfill the conditions of these regulations, the lessor is entitled to withdraw from the contract. The lessee is not entitled to any compensation in this case."])},
    "text-p9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["g) Irrespective of expressly written agreements, all statements made by the Lessee in the rental contract constitute an essential part of this contract. In particular, the Lessee bindingly declares with his signature that he is capable of paying the rental price."])},
    "text-p10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h) Should the Lessor not be able to provide the booked vehicle group, the Lessor reserves the right to provide an alternative vehicle or to withdraw from the contract and to refund the accrued rental fee."])},
    "text-p11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["II RENTAL PRICE AND DEPOSIT"])},
    "text-p12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The rental price and insurance coverage are stated in the respective valid price list of the lessor. The rental price plus any deposit is due in advance. The method of payment is specified by the lessor. If payment is made by credit card, the lessor is entitled to charge any damages or deductibles and deposits to the credit card. If payment is made by EC Cash, the lessee agrees that additional claims arising from the contractual relationship may be collected by direct debit. Should a defect occur during the rental period, this shall not give rise to any claim by the Lessee to a reduction in the rental price. The Lessor shall endeavor to provide the Lessee with a replacement vehicle without delay."])},
    "text-p13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["III AUTHORIZED DRIVERS"])},
    "text-p14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The vehicle may only be driven by the Lessee and the drivers specified in the rental agreement. All the provisions of this contract with respect to the Lessee shall also apply to the respective authorized driver (see §IV). The Lessee is not entitled to lend or rent the vehicle to a third person, not even for short-term use, unless agreed in writing in the rental contract."])},
    "text-p15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IV VEHICLE USE"])},
    "text-p16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a) The vehicle is handed over by the Lessor with a tank level indicating at least reserve (approx. 5 liters). The Lessee shall return the vehicle according to the tank level with which he took over the vehicle. In the event of a difference in the tank level when the vehicle is returned, a lump sum of EUR 8.00 per indicator bar will be charged. If the vehicle is returned with a tank content plus, this will not be reimbursed. Fuel costs during the contract period will be charged to the lessee. Per rental day (24 hours) a free mileage allowance noted in the rental contract is included. Additional free kilometers can be booked before rental for a flat fee. Additional kilometers will be charged with EUR 0,18 per kilometer after return of the vehicle."])},
    "text-p17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b) The rental vehicles are serviced regularly. If a repair becomes necessary during the rental period in order to ensure the operation or road safety of the vehicle, the lessor must be contacted in any case and his consent obtained. The Lessor shall bear the repair costs upon presentation of the corresponding invoice, unless the Lessee is to be held liable for the damage (see §V c)."])},
    "text-p18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c) The Lessee is prohibited from using the vehicle for motor sport events, competitions, test purposes, for further rental, for commercial passenger transport and other illegal purposes. Trips outside the federal territory require the written consent of the lessor."])},
    "text-p19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V INSURANCE AND LIABILITY"])},
    "text-p20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a) The rented vehicles are insured with a maximum coverage for personal injury and property damage with a total amount of EUR 8 million. The liability insurance cover only includes property damage, personal injury and pure financial losses of third parties that occur during the operation of the vehicle, but not damage to the rented vehicle and to objects that the lessee or third parties have brought into the vehicle. The provisions of the VVG and the AKB apply accordingly to the insurance cover and the benefits, insofar as these do not already apply directly from the contract."])},
    "text-p21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b) Without inclusion, there is no vehicle insurance cover for the vehicles in the form of partial or full insurance, which covers direct damage, i.e. damage, destruction or loss of the rented motor vehicle or parts thereof. This can be included against inclusion of a fully comprehensive insurance with a respective agreed deductible."])},
    "text-p22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c) The lessee is liable for all damage to or in the vehicle or the loss of the vehicle including vehicle parts and accessories occurring during the term of the rental agreement, furthermore for the costs of recovery and towing of the vehicle, the costs of damage assessment, costs for experts, loss of rental income, reduced value, costs of repair or replacement of the advertising film as well as other costs, insofar as these are not the responsibility of a third party known to the lessor and are to be borne."])},
    "text-p23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following loss events are examples:"])},
    "text-p24": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destruction of or damage to the vehicle due to self-inflicted accident Fire and explosion"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theft, robbery or unauthorized use"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct impact of storm, hail, lightning or flood external impact of animals and furred game"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snow avalanches"])},
      "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakage damage to the glazing"])},
      "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fire or scorching damage to the wiring vandalism damage"])},
      "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct damage caused by marten bites"])},
      "p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By the additional conclusion of a comprehensive insurance you can protect yourself against these damages, with a deductible of EUR 750.00. Against further payment, this deductible can be reduced to EUR 350.00. In this case, §V d) Theft insurance must be observed."])}
    },
    "text-p25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This does not include:"])},
    "text-p26": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damage to the vehicle and tires resulting from operating errors Damage to the advertising film"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costs for the internal processing of a damage event"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costs of processing fines, fees and penalties (see § I f)"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costs for interventions by authorities, salvage and towing costs, expert costs"])}
    },
    "text-p27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d) In case of theft of the vehicle and if the customer cannot return the keys received at the time of rental, the full liability amount in the form of the value of the vehicle is considered agreed, as well as if the lessee can be proven to have participated in the theft. Moreover, the Lessee is obliged to inform the Lessor immediately by telephone or telegraph. Opposing claims must not be acknowledged, neither verbally nor in writing. Upon return of the vehicle, the Lessee shall notify the Lessor of all damages, operational faults, as well as accident damages without being asked to do so. If the lessee leaves the rental car to a third person not listed in the rental agreement, the lessee and the third person are liable as joint and several debtors without limitation in case of damage to the rental car."])},
    "text-p28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIII LIABILITY OF THE LESSOR"])},
    "text-p29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claims for damages by the tenant against the landlord from the rental agreement, regardless of the reason, are excluded, unless the damage is based on a grossly negligent breach of contract by the landlord or § I b.2 applies."])},
    "text-p30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IX VEHICLE RETURN"])},
    "text-p31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The lessee is obliged to return the vehicle to the lessor at the agreed place at the end of the rental period. We recommend to be at the agreed place at least half an hour before the return time. The return can be done only during business hours, unless otherwise agreed. Vehicle returns must be made no later than 15 minutes before the close of business. The vehicle must be returned at the agreed time, but no later than 30 minutes after that time. An additional day will be charged for returns made 31 minutes or later. If the vehicle continues to be driven without an agreement, a fee of EUR 50,- will be charged for each additional day not agreed upon. The vehicle is to be returned in proper condition. This means in particular that the vehicle - as far as necessary - has to be cleaned inside and outside and has to be restored to the condition it was in when it was handed over. The vehicle is to be returned with at least the fuel level agreed upon when the vehicle was handed over. If the self-cleaning is not carried out by the lessee, the lessor will charge a fee of EUR 15.00 in cleaning costs. Damage to the vehicle must be reported without being asked. Excessive wear and tear, as well as damage caused by excessive vehicle stress (e.g., clutch or tires) shall be borne by the renter."])},
    "text-p32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X NON-FULFILLMENT"])},
    "text-p33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The lessor is not liable for non-performance due to technical defects or accidents of the vehicle. The place of jurisdiction and performance is the registered office of the Lessor."])},
    "text-p34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATA STORAGE TERMS"])},
    "text-p35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" a) Users' personal data will be kept without prejudice to the duration of the contract (including any renewals) and for the 10 years following its term, termination or withdrawal in cases where retention for a later period is required for disputes, requests from competent authorities or in accordance with applicable legislation;"])},
    "text-p36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b) For marketing purposes related to the sending of promotional materials and use in the context of analysis and commercial studies, as well as consumption habits, the personal data of users will be kept for the duration of the contract and for a period of 5 years thereafter."])},
    "text-p37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XI USERS' RIGHTS IN RELATION TO THEIR PERSONAL DATA"])},
    "text-p38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may exercise the following rights at any time:"])},
    "text-p39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request further information regarding the content of this information, access personal data, obtain its rectification or erasure or the restriction of processing concerning him (in the cases provided for by law), object to processing (in the cases provided for by law); data portability (in the cases provided for by law); revocation of consent, if any. Withdrawal of consent does not affect the lawfulness of the processing based on the consent given before the withdrawal. Propose a complaint to the supervisory authority (data protection guarantor). In relation to the processing of personal data concerning him, as described above, the Customer freely expresses his consent in accordance with and for the purposes of the law. If any provision of this Lease is void, such voidness shall not determine the invalidity of the other provisions of this Lease. If the Customer decides to pay in a currency other than the one in which the rental costs were stated, the equivalent will be calculated using the exchange rate published by the Bundesbank plus 4% as reimbursement of bank charges and commissions and exchange rate risk. For the processing of personal data for the sending of promotional materials and their use in the context of analysis and commercial studies and consumption habits, in accordance with the information provided in Article 10 (Privacy) point 2 of this contract. After reading the Privacy Policy and the General Rental Conditions, the Customer declares to expressly approve all the clauses."])}
  },
  "text": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
    "created-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed SuccessFully!"])},
    "completed-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed SuccessFully!"])},
    "rese-success-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request reservation"])},
    "rese-success-text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your request!"])},
    "rese-success-text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Roadstars staff will get back to you as soon as possible!"])},
    "no-data-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data found!"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "go-to-home-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Home Page"])},
    "your-reservation-is-successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Reservation is Successful"])},
    "your-reservation-is-declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Reservation is Declined"])},
    "your-reservation-is-unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Reservation is Unsuccessful"])},
    "success-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request sent successfully"])},
    "error-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something wrong please try again"])}
  }
}