import TheDefault from "@/layouts/TheDefault.vue"
import Home from '../pages/Home.vue'
import PageNotFound from '../pages/PageNotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Startseite',
      layout: TheDefault,
    }
  },
  {
    path: '/datenschutz',
    name: "Privacy",
    component: () => import('../pages/Privacy.vue'),
    meta: {
      title: 'Datenschutz',
      layout: TheDefault,
    }
  },
  {
    path: '/impressum',
    name: 'Legal',
    component: () => import('../pages/Legal.vue'),
    meta: {
      title: 'Impressum',
      layout: TheDefault,
    }
  },
  {
    path: '/Terms',
    name: 'Terms',
    component: () => import('../pages/Terms.vue'),
    meta: {
      title: 'Geschäftsbedingungen',
      layout: TheDefault,
    }
  },
  {
    path: '/confirmation/:id',
    name: "confirmation",
    component: () => import('../pages/Confirmation.vue'),
    meta: {
      title: 'Confirmation',
      layout: TheDefault
    }
  },
  {
    path: '/reservation-decline/:id',
    name: "reservation-decline",
    component: () => import('../pages/declineConfirmation.vue'),
    meta: {
      title: 'Confirmation',
      layout: TheDefault
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: "Not found",
    component: PageNotFound,
    meta: {
      title: 'Page not found',
      layout: TheDefault
    }
  },
]

export default routes
