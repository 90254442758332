<!-- eslint-disable no-unused-vars -->
<script setup>
import { nextTick, ref, watch, onMounted } from 'vue'
import LangSwitcher from "./LangSwitcher.vue"
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const menus = ref(null)
const isMobile = ref(false)
const activeLink = ref('home')

const showMobileMenu = () => {
  isMobile.value = !isMobile.value
}

const scrollToDiv = (id) => {
  const el = document.querySelector(id)
  if (!el) {
    setTimeout(() => {
      scrollToDiv(id)
    }, 300)
    return false
  }

  const ofsetH = el.offsetTop - 80
  nextTick(() => {
    window.scrollTo({
      top: ofsetH,
      behavior: "smooth",
    })
  })

  return true
}

const scrollToRoute = (section) =>{
  router.replace(`/?to=${section}`)
  isMobile.value = false
}
watch(() => route, (path) => {
  if (path?.query?.to) {
    scrollToDiv(`#${route.query.to}`)
    isMobile.value = false
  }
}, { immediate: true, deep: true })

</script>

<template>
  <header :class="{ show: isMobile }" class="header">
    <div>
      <div class="header__sd-nav">
        <app-link class="header__sd-nav-logo" path="/">
          <img src="@/assets/images/logo.svg" alt="logo" />
        </app-link>

        <button @click="showMobileMenu" class="base-btn header__nav-toggler">

          <span v-if="isMobile">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <span v-else>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 12H21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3 6H21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3 18H21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
        </button>
      </div>
      <nav ref="menus" :class="{ show: isMobile }" class="header__navbar container-fluid">
        <app-link class="header__logo" path="/">
          <img class="w-100" src="@/assets/images/logo.svg" alt="logo" />
        </app-link>

        <ul class="header__nav-menu header__nav-menu-left align-ite align-items-baseline">
          <!-- Home -->
          <li class="header__nav-menu-item">
            <p @click="scrollToRoute('hero')" class="nav-link">{{ t("nav.home") }}</p>
          </li>
          <!-- About -->
          <li class="header__nav-menu-item">
            <p @click="scrollToRoute('ueberuns')" class="nav-link">{{t("nav.about") }} </p>
          </li>
          <!-- Car -->
          <li class="header__nav-menu-item">
            <p @click="scrollToRoute('fahrzeuge')" class="nav-link">{{ t("nav.car") }} </p>
          </li>
          <!-- Feature -->
          <li class="header__nav-menu-item">
            <p @click="scrollToRoute('leistungen')" class="nav-link">{{ t("nav.features")}} </p>
          </li>
          <!-- Contact -->
          <li class="header__nav-menu-item">
            <p @click="scrollToRoute('kontakt')" class="nav-link">{{ t("nav.contact") }}</p>
          </li>
          <!-- Lang switcher -->
          <li class="header__nav-menu-item">
            <LangSwitcher :showMobileMenu="showMobileMenu" />
          </li>
        </ul>

      </nav>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header {
  padding: 10px 15px;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-light-grey);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99999;
  right: 0;
  height: 80px;

  @include responsive-max("md") {
    height: 70px;
  }

  &.show {
    height: auto;
  }

  &__navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    transition: 0.3s ease;

    @include responsive-max("md") {
      height: auto;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      height: 0;

      &.show {
        overflow: visible;
        height: auto;
      }
    }
    
  }

  &__sd-nav {
    display: none;

    @include responsive-max("md") {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &-logo {
        height: 50px;
        width: auto;

        img {
          // width: 70%;
          height: 100%;
        }
      }
    }
  }

  &__logo {
    width: auto;
    height: 100%;

    @include responsive-max("md") {
      display: none;
    }

    img {
      width: 70%;
      height: 100%;
    }
  }

  &__nav-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include responsive-max("md") {
      flex-direction: column;
      align-items: start;

      &-right {
        flex-direction: row;
        margin-bottom: 0.75rem;
      }

      &-left {
        padding-top: 1rem;
      }
    }

    &-item {
      &:not(:last-child) {
        margin-right: 2rem;
        @media (min-width: 769px) and (max-width: 890px) {
          margin-right: 0.8rem;
        }
      }

      @include responsive-max("md") {
        &:not(:last-child) {
          margin-right: 0;
        }

        margin-bottom: 1rem;
      }

      .nav-link,
      .nav-link.active.exact-active {
        font-weight: 500;
        // text-transform: capitalize;
        font-size: 1rem;
        color: var(--color-text);
        padding: 0 5px;

        &.navActive,
        &:hover {
          color: var(--color-primary);
          cursor: pointer;
        }
      }
    }
  }

  &__nav-btn,
  &__nav-btn.active.exact-active {
    background-color: var(--color-dark);
    color: var(--color-white);
    padding: 5px 15px;
    border-radius: 4px;
    font-size: 1rem;
    transition: all 0.4s ease;

    &.navActive,
    &:hover {
      background-color: var(--color-primary);
    }
  }
}
</style>
