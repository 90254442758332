<script setup>
import { ref, onMounted, watch,  defineEmits, defineProps, computed } from 'vue'
import { DatePicker } from 'v-calendar'
import { useI18n } from 'vue-i18n'
import { useValidationStore } from '@/stores/validation'

const validationStore = useValidationStore()
const { locale } = useI18n({ useScope: 'global' })

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
   modelValue: {
      type: [Object, Date, String, Number, null],
      required: true
   },
   label: { type: String },
   labelPositioning: {
      type: Boolean,
      default: false,
   },
   labelClasses: { type: String, default: '' },
   errorField: { type: String },
   disabled: { type: Boolean, default: false },
   readonly: { type: Boolean, default: false },
   placeholder: { type: String },
   stack: { type: Boolean, default: false },
   minDate: { type: [Date, String] },
   maxDate: { type: [Date, String] },
   errors: {
      type: Object,
      default: () => { }
   }
})

const date = ref()
onMounted(() => {
   if (props.modelValue) {
      date.value = props.modelValue
   }
})
watch(() => props.modelValue, () => {
   date.value = props.modelValue
})
watch(() => date.value, (val) => {

   emit('update:modelValue', val)

   delete validationStore.errors[props.errorField]
})

let masks = {
   input: []
}
if (locale.value == 'en' || locale.value == 'de') {
   masks = {
      input: ['DD/MM/YYYY', 'L']
   }
}
const modelConfig = {
   type: 'string',
   mask: 'DD/MM/YYYY', // Uses 'iso' if missing
}

const hasError = computed(() => {
   return props.errorField && validationStore.errors[props.errorField]
})
const errorMsg = computed(() => {
   return validationStore.errors[props.errorField] && validationStore.errors[props.errorField][0]
})

const getError = computed(() => {
   if (props.errors?.$errors?.length) {
      const find = props.errors?.$errors?.find(err => err.$property == props.errorField)
      if (find) {
         return find.$message
      }
   }
   return ''
})

// const maxDate = ref((new Date).setFullYear(new Date().getFullYear() - 18))

const detectMouseWheelDirection = (e) =>{
   let  delta = null,
   direction = false;

    if ( e.wheelDelta ) { // will work in most cases
        delta = e.wheelDelta / 100;
    } 
    else if ( e.detail ) { // fallback for Firefox
        delta = -e.detail / 2;
    }
    if ( delta !== null ) {
        direction = delta > 0 ? 'up' : 'down';
    }

    return direction;
}


const calendar = ref(null)
const onWheel = async (e) => {
   e.stopImmediatePropagation()
   try{
      const direction = detectMouseWheelDirection(e)
      if(direction == 'up'){
         await calendar.value.move(-1)
      }
      if(direction == 'down'){
         await calendar.value.move(1)
      }
   }
   catch(err){
      console.log(err)
   }
}

</script>

<template>
   <DatePicker ref="calendar" @wheel.prevent="onWheel" :step="1" class="inline-block h-full labelPositioning position-relative datePicker" @scroll="movePrevMonth()" color="red" titlePosition="left" transition="slide-v" format="dd/mm/yyyy" v-model="date" :max-date='(new Date).setFullYear(new Date().getFullYear() - 18)' type="date" :popover="{visibility: 'click'}" :masks="masks" :locale="locale" :model-config="modelConfig">
      <template v-slot="{ inputValue, inputEvents }">
         <div class="d-flex align-items-center position-relative">
            <input
               :value="inputValue"
               v-on="inputEvents"
               class="form-control"
               :class="{'pointer': !props.readonly}"
            />
            <div
               class="toggle button position-absolute top-0 end-0 toggle-bar d-flex align-items-center justify-content-end pt-2 pe-3" 
            >
               <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.6579 4H5.55267C4.3609 4 3.39478 4.89543 3.39478 6V20C3.39478 21.1046 4.3609 22 5.55267 22H20.6579C21.8497 22 22.8158 21.1046 22.8158 20V6C22.8158 4.89543 21.8497 4 20.6579 4Z" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M17.4211 2V6" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.78955 2V6" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3.39478 10H22.8158" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
            </div>
         </div>
         <label 
            class="label label-position position-absolute ms-2 px-1"
            :style="modelValue ? 'top: -8px; background: white;' : ''"
         >{{label}}</label>
      </template>
   </DatePicker>
   <p v-if="getError || hasError" class="text-danger text-13 mt-1 mb-0">{{getError || errorMsg }}</p>
</template>

<style lang="scss" scoped>
.labelPositioning{
   .label.label-position{
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6) !important;
      top: 15px;
      left: 0; 
      transition: 0.2s ease all;
      pointer-events: none;
      z-index: 2; 
   }
   input{
      width: 100%;
      padding: 0.5rem 0.75rem;
      height: 45px !important;
      border-radius: 4px;
      color: var(--color-text);
      outline: 0;
      font-family: var(--font-primary);
      border: 1px solid #dddd;
      font-weight: 400;
      transition: all 0.2s ease;
      // z-index: 2;
      background: transparent; 
   }  
   .toggle-bar{
      background: transparent;
      cursor: pointer; 
      // z-index: 1;
      // width: 50%;
   }
   
}
</style>
