<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 0C7.392 0 0 7.168 0 16C0 24.832 7.392 32 16.5 32C25.608 32 33 24.832 33 16C33 7.168 25.608 0 16.5 0ZM13.2 24L4.95 16L7.2765 13.744L13.2 19.472L25.7235 7.328L28.05 9.6L13.2 24Z"
    />
  </svg>
</template>
