<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 2.05625L18.885 0L10.5 8.15208L2.115 0L0 2.05625L8.385 10.2083L0 18.3604L2.115 20.4167L10.5 12.2646L18.885 20.4167L21 18.3604L12.615 10.2083L21 2.05625Z"
      fill="black"
    />
  </svg>
</template>
