<script setup>
import { defineEmits, ref, computed } from 'vue'
import BInput from "@/components/base/BInput.vue"
import BTextarea from "@/components/base/BTextarea.vue"
import BSelect from "@/components/base/BSelect.vue"
import CalenderControl from "@/components/base/CalenderControl.vue"
// import { DatePicker } from 'v-calendar'
import { useAxios, api } from '@/use/useAxios'
import { useReservationStore } from '@/stores/reservation'
import { useI18n } from 'vue-i18n'

const emit = defineEmits(['submited'])

const { t, locale } = useI18n({ useScope: 'global' })
const { bookingForm, clearBookingForm } = useReservationStore()

const { data: countryData } = useAxios(`get-countries?lang=${locale.value}`)

const getCountries = computed(() => {
  if (countryData.value?.countrys) {
    if(locale.value == 'de'){
      const germany = countryData.value.countrys.find(c => c.id == 431)
      return [germany, ...countryData.value.countrys]
    }
    else{
      const germany = countryData.value.countrys.find(c => c.id == 82)
      return [germany, ...countryData.value.countrys]
    }
  } else {
    return []
  }
})

const requestLoading = ref(false)

const nameTitleOptions = [
  {
    id: 'Mr.',
    label: t('home-booking-request-form.mr'),
  },
  {
    id: 'Mrs.',
    label: t('home-booking-request-form.mrs'),
  }
]

async function submitRequest() {
  try {
    requestLoading.value = true
    await api.post('request-booking', {
      ...bookingForm,
      lang: locale.value,
    })
    emit("submited", { status: true })
    clearBookingForm()
  }
  catch (error) {
    console.error(error)
  } finally {
    requestLoading.value = false
  }
}

function requestRejected() {
  emit("submited", { status: false })
}

</script>

<template>
  <form class="booking-request__form">
    <div class="row">
      <div class="col-md-6 mb-3">
        <!-- Company Name -->
        <b-input v-model="bookingForm.company_name" :label="t('home-booking-request-form.company')" />
      </div>
      <div class="col-md-6 mb-3">
        <!-- Name Title -->
        <b-select v-model="bookingForm.name_title" labelPositioning :options="nameTitleOptions ?? []"
          display-property="label" value-property="id" :label="t('home-booking-request-form.name-title')" errorField="name_title" required
          outline></b-select>
      </div>
    </div>
    <!-- First / Last Name -->
    <div class="row">
      <div class="col-md-6 mb-3">
        <b-input v-model="bookingForm.first_name" :label="t('home-booking-request-form.first-name')"
          errorField="first_name" />
      </div>
      <div class="col-md-6 mb-3 m-last__name">
        <b-input v-model="bookingForm.last_name" :label="t('home-booking-request-form.last-name')"
          errorField="last_name" />
      </div>
    </div>
    <!-- Date / Place of Birth -->
    <div class="row">
      <div class="col-md-6 mb-3">
        <CalenderControl v-model="bookingForm.date_of_birth" labelPositioning :label="$t('home-booking-request-form.date-birth')" errorField="date_of_birth" />
      </div>
      <div class="col-md-6 mb-3 m-last__name">
        <b-input v-model="bookingForm.place_of_birth" :label="t('home-booking-request-form.place-birth')"
          errorField="place_of_birth" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3">
        <!-- Street and Address -->
        <b-input v-model="bookingForm.address" :label="t('home-booking-request-form.street')" errorField="address" />
      </div>
      <div class="col-md-6 mb-3">
        <!-- Zip -->
        <b-input v-model="bookingForm.zip" :label="t('home-booking-request-form.zip')" errorField="zip" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 mb-3">
        <!-- City Name -->
        <b-input v-model="bookingForm.city" :label="t('home-booking-request-form.city')" errorField="city" />
      </div>
      <div class="col-md-6 mb-3">
        <!-- Country Name -->
        <b-select v-model="bookingForm.country" labelPositioning firstChildStyle :options="getCountries" display-property="country_name"
          value-property="id" :label="t('home-booking-request-form.country')" errorField="country" required outline></b-select>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 mb-3">
        <!-- Phone -->
        <b-input v-model="bookingForm.phone" :label="t('home-booking-request-form.phone')" errorField="phone" />
      </div>
      <div class="col-md-6 mb-3">
        <!-- Email -->
        <b-input v-model="bookingForm.email" :label="t('home-booking-request-form.email')" errorField="email" />
      </div>
    </div>

    <!-- Message -->
    <b-textarea v-model="bookingForm.message" :label="t('home-booking-request-form.message')" :height="150"
      errorField="message" />

    <!-- Actions buttons -->
    <div class="booking__form-acitons">
      <button class="btn btn-link text-decoration-none text-dark text-14 fw-500 px-1 me-4" @click="requestRejected">
        {{ t("home-booking-request-form.cancel") }}
      </button>
      <button :disabled="requestLoading" @click="submitRequest" class="base-btn base-btn__sucess" >
        <span v-if="requestLoading">{{ t("button.please-wait") }}...</span>
        <span v-else>{{ t("home-booking-request-form.request") }}</span>
      </button>
    </div>
  </form>
</template>

<style lang="scss">
.booking-form {
  textarea::placeholder {
    font-weight: 500 !important;
  }
}
</style>

<style scoped>
.date__picker {
  margin-bottom: 1rem;
}

.inline-input__inner {
  display: flex;
  grid-gap: 1rem;
}

.booking__form-acitons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}

@media (max-width: 576px) {
  .inline-input__inner {
    display: block;
  }

  .inline-input__inner .last-input {
    margin-bottom: 1rem;
  }

  .m-last__name {
    margin-top: 1rem;
  }
}
</style>
