import { defineStore } from 'pinia'

export const useReservationStore = defineStore({
  id: 'reservation',
  state: () => ({
    keys: {
      requestFormKey: 1,
    },
    bookingForm: {
      vehicleClass: "",
      location: "",
      pickup_date: "",
      pickup_time: "",
      return_date: "",
      return_time: "",

      company_name: "",
      name_title: "", 
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      message: "",
      date_of_birth: "",
      place_of_birth: "",
      address: "",
      country: "",
      city: "",
      zip: "",
      id_no: "",
      driving_license: "",
      lang: "",
    }
  }),

  actions: {
    clearBookingForm() {
      this.bookingForm = {
        vehicleClass: "",
        location: "",
        pickup_date: "",
        pickup_time: "",
        return_date: "",
        return_time: "",

        company_name: "",
        name_title: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        message: "",
        date_of_birth: "",
        place_of_birth: "",
        address: "",
        country: "",
        city: "",
        zip: "",
        id_no: "",
        driving_license: "",
        lang: "",
      }
    }
  }
})
