<template>
  <div class="b-textarea position-relative" :style="[{ height: height + 'px' }]">
    <textarea :class="{
        error: error,
        outline: outline,
        noresize: !resize,
      }" :value="modelValue" cols="30" rows="6" :placeholder="placeholder" :required="required" @input="handleModelValue($event.target.value)"></textarea>
    <label class="label position-absolute left-0 ms-2 px-1" :style="modelValue.length > 0 ? 'top: -8px; background: white;' : ''">{{label}}</label>
  </div>
</template>

<script>
export default {
  name: "BInput",
  props: {
    placeholder: {
      type: String,
      required: false,
      default: " ",
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: " ",
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    height: {
      type: Number,
      default: 100,
    },
    resize: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleModelValue(mV){
      this.$emit("update:modelValue", mV)
    }
  }
}
</script>

<style scoped lang="scss">
.b-textarea + .b-textarea {
  margin-top: 1rem;
}

.b-textarea {
  position: relative;
  margin-bottom: 1rem;
  textarea {
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    width: 100%;
    padding: 0.4rem 0.75rem;
    font-size: 1rem;
    transition: border-width 0.2s linear;
    color: var(--color-text);
    font-family: var(--font-primary);
    height: 100%;
  }
  label {
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    top: 15px;
    left: 0; 
    transition: 0.2s ease all;
    pointer-events: none;
  }
  textarea:focus ~ label{
    font-size: 12px; 
    top: -8px;
    background: white;
    opacity:1;
  }
}

.b-textarea textarea.error {
  border-color: var(--color-error);
  caret-color: var(--color-error);
  /* background-color: rgba(255, 82, 82, 0.18); */
}

.error::placeholder {
  color: var(--color-error);
}

.noresize {
  resize: none;
}
</style>
