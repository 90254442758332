
<script setup>
import { ref, reactive, computed, watch } from 'vue'
import HomeHeroModalBooking from "./modals/HomeHeroModalBooking.vue"
import ModelConfirm from "../common/modals/ModelConfirm.vue"
import DateRangePicker from "../base/datepicker/DateRangePicker.vue"
import TimePicker from "../base/timepicker/TimePicker.vue"
import BSelect from "../base/BSelect.vue"
import IconLocationFill from "../../icons/IconLocationFill.vue"
import { api } from "@/plugins/api"
import timeSchedules from "@/static/times.json"
import { useReservationStore } from '../../stores/reservation'
import moment from 'moment'
import { useI18n } from 'vue-i18n'

const { t, locale } = useI18n({ useScope: 'global' })

const { bookingForm, keys } = useReservationStore()

const isSubmitedBookingForm = ref(false)
const isShowBookingFrom = ref(false)

const vehicleClasses = ref([])
const locations = ref([])

const openHours = reactive({
  en: ["MO - FR 10am - 6pm", "SA 10am - 4pm"],
  de: ["MO - FR 10:00 - 18:00", "SA 10:00 - 16:00"],
})

const requestForm = reactive({
  vehicleClass: "",
  location: "",
  dates: {
    start: '',
    end: '',
  },
  pickupTime: "00:00:00",
  dropTime: "00:00:00",
  carImage: "hero.png",
})

requestForm.dates.start = addDays(new Date(), 7)
requestForm.dates.end = addDays(new Date(), 9)

const requestFormInit = reactive({
  vehicleClass: "",
  location: "",
  dates: {
    start: "",
    end: "",
  },
  pickupTime: "00:00:00",
  dropTime: "00:00:00",
  carImage: "hero.png",
})

async function getVehicleClasses() {
  try {
    const res = await api.get(`/get-vhehicle`)
    vehicleClasses.value = res.data.vichiletype
  } catch (err) {
    console.error(err)
  }
}
getVehicleClasses()

async function getLocations() {
  try {
    const res = await api.get(`/get-location`)
    locations.value = res.data.lcoations
  } catch (err) {
    console.error(err)
  }
}
getLocations()

function addDays(date, days) {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

const vehicleType = ref(null)
const location = ref(null)
const pickupDate = ref(null)
const returnDate = ref(null)
const pickupTime = ref(null)
const returnTime = ref(null)

function requestToBook() {
  vehicleType.value.vlaidateForm()
  location.value.vlaidateForm()
  pickupDate.value.vlaidateForm()
  returnDate.value.vlaidateForm()
  pickupTime.value.vlaidateForm()
  returnTime.value.vlaidateForm()

  if (
    vehicleType.value.errorMessage ||
    location.value.errorMessage ||
    pickupDate.value.errorMessage ||
    returnDate.value.errorMessage ||
    returnTime.value.errorMessage ||
    pickupTime.value.errorMessage
  ) {
    return
  }

  document.querySelector("body").classList.add("modal-open")
  isShowBookingFrom.value = true
}

function resetBookingForm() {
  Object.assign(requestForm, { ...requestFormInit })
  requestForm.dates.start = addDays(new Date(), 7)
  requestForm.dates.end = addDays(new Date(), 9)
}

function hideBookingForm(e) {
  isShowBookingFrom.value = false
  document.querySelector("body").classList.remove("modal-open")

  vehicleType.value.submitedForm()
  location.value.submitedForm()
  pickupDate.value.submitedForm()
  returnDate.value.submitedForm()
  pickupTime.value.submitedForm()
  returnTime.value.submitedForm()
  resetBookingForm()

  if (e.status) {
    isSubmitedBookingForm.value = true
    document.querySelector("body").classList.add("modal-open")
    setTimeout(() => {
      isSubmitedBookingForm.value = false
      document.querySelector("body").classList.remove("modal-open")
    }, 3000)
  }
}

function hideConfirmModal() {
  isSubmitedBookingForm.value = false
}

const getTimes = computed(() => {
  return locale.value === "de"
    ? timeSchedules.de
    : timeSchedules.en
})

const getHours = computed(() => {
  return locale.value === "de" ? openHours.de : openHours.en
})


watch(() => requestForm, (form) => {
  bookingForm.vehicleClass = form.vehicleClass
  bookingForm.location = form.location
  bookingForm.location = form.location

  bookingForm.pickup_date = moment(new Date(form.dates.start)).format('YYYY-MM-DD')
  bookingForm.return_date = moment(new Date(form.dates.end)).format('YYYY-MM-DD')

  bookingForm.pickup_time = form.pickupTime
  bookingForm.return_time = form.dropTime
}, { deep: true })

watch(() => locale.value, () => {
  getVehicleClasses()
  getLocations()
})

watch(() => isSubmitedBookingForm.value, (val) => {
  if (!val) {
    keys.requestFormKey += 1
  }
})

watch(() => requestForm.dates, (d) => {
  if (moment(d.start).isSame(d.end)) {
    const isMin = moment(this.requestForm.dropTime, 'h:mma').isBefore(moment(this.requestForm.pickupTime, 'h:mma'))
    const isEqal = this.requestForm.pickupTime == this.requestForm.dropTime
    if (isMin || isEqal) {
      this.requestForm.dropTime = ''
    }
  }
}, { deep: true })

</script>

<template>
  <div class="search-box">
    <b-select ref="vehicleType" v-model="requestForm.vehicleClass" :options="vehicleClasses ?? []" display-property="classname" value-property="id" :label="t('home-search-form.vehicle-class')" :placeholder="t('home-search-form.vehicle-class')" required outline>
    </b-select>

    <div class="search-box__field">
      <label class="form__label">{{
        t("home-search-form.pick-location")
      }}</label>
      <b-select ref="location" v-model="requestForm.location" :vehicleClasses="vehicleClasses" :options="locations ?? []" display-property="location_name" value-property="id" :placeholder="t('home-search-form.pick-location')" required outline icon class="search-box__select">
        <IconLocationFill />
      </b-select>
    </div>

    <div class="search-box__field">
      <label class="form__label">{{
        t("home-search-form.pickup-date")
      }}</label>
      <div class="row">
        <div class="col-6">
          <date-range-picker ref="pickupDate" v-model="requestForm.dates" formatted="dd MMM yyyy" :sd-title="t('home-search-form.pickup-date')" :label-start-sd="t('home-search-form.pickup')" :label-end-sd="t('home-search-form.return')" :button-text-sd="t('home-search-form.button')" :placeholder="t('home-search-form.pickup')" required />
        </div>
        <div class="col-6">
          <time-picker ref="pickupTime" name="pickupTime" v-model="requestForm.pickupTime" :title-sd="t('home-search-form.pickup-time')" :label-start-sd="t('home-search-form.pickup')" :label-end-sd="t('home-search-form.return')" :button-text-sd="t('home-search-form.button-time')" :placeholder="t('home-search-form.time')" :options="getTimes" displayProperty="time" valueProperty="24_hour" :open-hours="getHours" :dates="requestForm.dates" :times="{
              start: requestForm.pickupTime,
              end: requestForm.dropTime,
            }" outline required />
        </div>
      </div>
    </div>

    <div>
      <label class="form__label">{{
        t("home-search-form.return-date")
      }}</label>
      <div class="row">
        <div class="col-6">
          <date-range-picker ref="returnDate" v-model="requestForm.dates" formatted="dd MMM yyyy" show-end-date :sd-title="t('home-search-form.return-date')" :label-start-sd="t('home-search-form.pickup')" :label-end-sd="t('home-search-form.return')" :button-text-sd="t('home-search-form.button')" :placeholder="t('home-search-form.return')" required />
        </div>
        <div class="col-6">
          <time-picker ref="returnTime" name="returnTime" v-model="requestForm.dropTime" :pickupTime="requestForm.pickupTime" :title-sd="t('home-search-form.return-time')" :label-start-sd="t('home-search-form.pickup')" :label-end-sd="t('home-search-form.return')" :button-text-sd="t('home-search-form.button-time')" :placeholder="t('home-search-form.time')" :options="getTimes" displayProperty="time" valueProperty="24_hour" :dates="requestForm.dates" :open-hours="getHours" :times="{
              start: requestForm.pickupTime,
              end: requestForm.dropTime,
            }" outline required />
        </div>
      </div>
    </div>

    <div class="d-grid search-box__action">
      <button class="base-btn base-btn__dark request_btn" @click="requestToBook">
        {{ t("home-search-form.request-botton") }}
      </button>
    </div>

    <Teleport to="body">
      <HomeHeroModalBooking v-if="isShowBookingFrom" :vehicleClasses="vehicleClasses" :locations="locations" :request-info="requestForm" @hideModal="hideBookingForm" />
      <ModelConfirm v-if="isSubmitedBookingForm" @hideModal="hideConfirmModal" />
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
.search-box {
  margin-top: 0.6rem;
  max-width: 500px;
  background-color: var(--color-white);
  border-radius: 0.25rem;
  padding: 2rem;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--color-light-grey);
  margin-bottom: 1rem;

  &__field {
    margin-top: 1rem;
  }

  &__action {
    margin-top: 1.5rem;
  }
}

.form-select__inner {
  width: 100%;
  position: relative;
}

.form-select__inner .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.form__select-box {
  outline: none;
  width: 100%;
  border-radius: 4px;
}

select.form-select.form__select-box {
  border-color: #ccc !important;
  padding: 0.6rem 0.8rem;
  padding-right: 2rem;
}

.form__select-box--icon {
  padding-left: 2.2rem !important;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #575757;
  margin-left: 0.2rem;
}

.search_location {
  position: absolute;
}

.request_btn {
  padding: 0.5rem;
}

.request_btn:hover {
  background-color: var(--color-primary);
}

.error__message {
  font-size: 0.875rem;
}

.form__label {
  font-weight: 500;
  color: var(--color-dark);
}

@media (max-width: 768px) {
  .date__picker {
    width: 100%;
  }
  .search-box {
    width: 100%;
    padding: 1rem;
    margin-top: 0rem;
  }
}
@media (max-width: 576px) {
  .date__picker {
    width: 100%;
  }
  .search-box {
    width: 100%;
    padding: 1rem;
    margin-top: 0rem;
  }
  .m_margin {
    margin-top: 1rem;
  }
}
</style>
