<script setup>
import ContactForm from "@/components/common/ContactForm"
import SectionHeader from "@/components/common/SectionHeader.vue"
import GoogleMap from "@/components/common/GoogleMap"
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <section id="kontakt" class="contact-section">
    <div class="container">
      <SectionHeader :title="t('home-contact.title')" :description="t('home-contact.description')" :description-width="60" />
      <div class="hours">
        <p class="time">{{ t("home-contact.hours-1") }}</p>
        <p class="time">{{ t("home-contact.hours-2") }}</p>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="contact-info__card">
                <div class="row">
                  <div class="col-md-6">
                    <div class="contact-info__card-contents w-100 d-flex flex-column align-items-center">
                      <div class="contact-info__card-icon">
                        <span class="icon"><svg width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 0.166626C5.87251 0.166626 0.916672 5.12246 0.916672 11.25C0.916672 19.5625 12 31.8333 12 31.8333C12 31.8333 23.0833 19.5625 23.0833 11.25C23.0833 5.12246 18.1275 0.166626 12 0.166626ZM12 15.2083C9.81501 15.2083 8.04167 13.435 8.04167 11.25C8.04167 9.06496 9.81501 7.29163 12 7.29163C14.185 7.29163 15.9583 9.06496 15.9583 11.25C15.9583 13.435 14.185 15.2083 12 15.2083Z" />
                          </svg>
                        </span>
                      </div>
                      <p class="contact-info__card-desc">
                        Kaiser-Friedrich Str. 65 10627 Berlin
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="contact-info__card-contents w-100 d-flex flex-column align-items-center">
                      <div class="contact-info__card-icon card-sp__icon">
                        <span class="icon"><svg width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 0.166626C5.87251 0.166626 0.916672 5.12246 0.916672 11.25C0.916672 19.5625 12 31.8333 12 31.8333C12 31.8333 23.0833 19.5625 23.0833 11.25C23.0833 5.12246 18.1275 0.166626 12 0.166626ZM12 15.2083C9.81501 15.2083 8.04167 13.435 8.04167 11.25C8.04167 9.06496 9.81501 7.29163 12 7.29163C14.185 7.29163 15.9583 9.06496 15.9583 11.25C15.9583 13.435 14.185 15.2083 12 15.2083Z" />
                          </svg>
                        </span>
                      </div>
                      <p class="contact-info__card-desc">
                        Schlossstraße 69, 12165 Berlin
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-md-6">
              <div class="contact-info__card">
                <div class="row w-100">
                  <div class="col-md-4">
                    <div class="contact-info__card-contents w-100 d-flex flex-column align-items-center">
                      <div class="contact-info__card-icon mb-3">
                        <span class="icon mb-3">
                          <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M34.8333 26.79V31.54C34.8351 31.981 34.7447 32.4174 34.5681 32.8215C34.3914 33.2255 34.1323 33.5882 33.8074 33.8863C33.4825 34.1844 33.0988 34.4113 32.6811 34.5526C32.2634 34.6939 31.8208 34.7463 31.3816 34.7067C26.5094 34.1773 21.8294 32.5124 17.7174 29.8458C13.8918 27.4149 10.6484 24.1714 8.21744 20.3458C5.54157 16.2152 3.87632 11.5124 3.3566 6.61832C3.31703 6.18048 3.36907 5.73919 3.50939 5.32256C3.64972 4.90593 3.87526 4.52308 4.17165 4.19839C4.46804 3.8737 4.82879 3.61428 5.23094 3.43665C5.63308 3.25902 6.06781 3.16707 6.50744 3.16666H11.2574C12.0258 3.15909 12.7708 3.4312 13.3534 3.93225C13.936 4.4333 14.3166 5.12911 14.4241 5.88999C14.6246 7.4101 14.9964 8.90264 15.5324 10.3392C15.7455 10.9059 15.7916 11.5218 15.6653 12.1139C15.539 12.706 15.2456 13.2495 14.8199 13.68L12.8091 15.6908C15.0631 19.6548 18.3452 22.9369 22.3091 25.1908L24.3199 23.18C24.7504 22.7543 25.2939 22.4609 25.886 22.3346C26.4782 22.2084 27.0941 22.2545 27.6608 22.4675C29.0973 23.0035 30.5898 23.3753 32.1099 23.5758C32.8791 23.6843 33.5815 24.0717 34.0836 24.6644C34.5857 25.257 34.8525 26.0135 34.8333 26.79Z" stroke="#4D4445" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </span>
                      </div>
                      <a href="tel:03091439989" class="contact-info__card-desc">030 91439989</a>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="contact-info__card-contents w-100 d-flex flex-column align-items-center">
                      <div class="contact-info__card-icon mb-3">
                        <span class="icon mb-3">
                          <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_586_18769)">
                              <path d="M27.5454 22.7278C27.0831 22.4903 24.7802 21.3622 24.3519 21.2095C23.9235 21.0483 23.6097 20.972 23.3001 21.447C22.9863 21.9178 22.0956 22.9653 21.8157 23.2834C21.5443 23.5972 21.2686 23.6354 20.8064 23.4021C18.0581 22.028 16.2557 20.9508 14.4448 17.8421C13.9655 17.0151 14.924 17.0744 15.8189 15.289C15.9715 14.9751 15.8952 14.7079 15.7765 14.4704C15.6577 14.2329 14.7247 11.9343 14.3345 10.997C13.957 10.0852 13.5669 10.2124 13.2827 10.1954C13.0113 10.1785 12.7017 10.1785 12.3878 10.1785C12.074 10.1785 11.5693 10.2972 11.141 10.7595C10.7126 11.2303 9.50391 12.3626 9.50391 14.6613C9.50391 16.9599 11.1791 19.1865 11.4081 19.5003C11.6456 19.8142 14.7035 24.5303 19.3983 26.5617C22.3671 27.8425 23.5291 27.9528 25.0135 27.7323C25.9169 27.5965 27.7787 26.6041 28.1646 25.5057C28.5506 24.4115 28.5506 23.4742 28.436 23.2791C28.3215 23.0713 28.0077 22.9526 27.5454 22.7278Z" fill="#4D4445"/>
                              <path d="M36.5243 11.6375C35.5659 9.36004 34.1918 7.31585 32.4402 5.56004C30.6886 3.80848 28.6444 2.43013 26.3627 1.47589C24.0301 0.496205 21.5534 0 19.0002 0H18.9154C16.3453 0.0127232 13.8558 0.521652 11.5147 1.52254C9.25426 2.48951 7.22703 3.86362 5.49243 5.61518C3.75783 7.36674 2.39645 9.40246 1.45493 11.6714C0.479482 14.021 -0.0124826 16.519 0.000240607 19.0891C0.0129638 22.0324 0.716982 24.9545 2.03595 27.567V34.0134C2.03595 35.0906 2.90962 35.9643 3.98685 35.9643H10.4375C13.05 37.2833 15.9721 37.9873 18.9154 38H19.0045C21.5449 38 24.0089 37.508 26.3288 36.5453C28.5978 35.5996 30.6377 34.2424 32.3851 32.5078C34.1366 30.7732 35.515 28.746 36.4777 26.4855C37.4786 24.1444 37.9875 21.6549 38.0002 19.0848C38.013 16.502 37.5125 13.9955 36.5243 11.6375V11.6375ZM30.1161 30.2134C27.1431 33.1567 23.1989 34.7768 19.0002 34.7768H18.9281C16.3708 34.7641 13.8304 34.1279 11.5868 32.9319L11.2306 32.7411H5.25917V26.7696L5.06832 26.4134C3.87234 24.1699 3.23618 21.6295 3.22345 19.0721C3.20649 14.8438 4.82234 10.8741 7.78685 7.88415C10.7471 4.8942 14.704 3.24018 18.9324 3.22321H19.0045C21.125 3.22321 23.1819 3.6346 25.1201 4.44888C27.0116 5.24196 28.708 6.38281 30.167 7.84174C31.6217 9.29643 32.7668 10.9971 33.5598 12.8886C34.3826 14.848 34.794 16.9261 34.7855 19.0721C34.7601 23.2962 33.1018 27.2531 30.1161 30.2134V30.2134Z" fill="#4D4445"/>
                            </g>
                            <defs>
                              <clipPath id="clip0_586_18769">
                                <rect width="38" height="38" fill="white"/>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </div>
                      <a href="tel:01733777718" class="contact-info__card-desc">01733777718</a>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="contact-info__card-contents w-100 d-flex flex-column align-items-center">
                      <div class="contact-info__card-icon card-sp__icon">
                        <span class="icon">
                          <svg width="32" height="26" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M28.6667 0.333374H3.33332C1.59166 0.333374 0.18249 1.75837 0.18249 3.50004L0.166656 22.5C0.166656 24.2417 1.59166 25.6667 3.33332 25.6667H28.6667C30.4083 25.6667 31.8333 24.2417 31.8333 22.5V3.50004C31.8333 1.75837 30.4083 0.333374 28.6667 0.333374ZM28.6667 6.66671L16 14.5834L3.33332 6.66671V3.50004L16 11.4167L28.6667 3.50004V6.66671Z" />
                          </svg>
                        </span>
                      </div>
                      <a :href="'mailto:' + 'info@roadstars-cars.de'" class="contact-info__card-desc">info@roadstars-cars.de</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- Contact Form -->
      <div class="row contact-form">
        <div class="col-md-6 mb-3">
          <ContactForm />
        </div>
        <!-- Google Map -->
        <div class="col-md-6 mb-3">
          <GoogleMap />
        </div>
      </div>
    </div>
  </section>
</template>



<style lang="scss" scoped>
.contact-section {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.contact-form {
  margin-top: 3rem;
}

.hours {
  text-align: center;
  margin-bottom: 2rem;

  p {
    margin-bottom: 0.25rem;
    font-weight: 500;
  }
}

@media only screen and (max-width: 576px) {
  .card-sp__icon {
    padding-top: 1.5rem;
  }
}
.contact-info__card {
  padding: 1.5rem 1rem 1rem 1rem;
  border-radius: 5px;
  background-color: var(--color-light-grey);
  text-align: center;
  min-height: 140px;
  display: flex;
  // align-items: center;
  justify-content: center;
  width: initial;

  @include responsive-max("sm") {
    margin-bottom: 1rem;
  }

  &-icon {
    margin-bottom: 1.5rem;

    svg {
      fill: var(--color-text);
    }
  }

  &-desc {
    font-weight: 500;
    color: var(--color-text);
    margin-bottom: 0;
    font-size: 1rem;
  }
}
</style>
