export default {
  "date-format": {
    "days": {
      "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So"])},
      "mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo"])},
      "tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di"])},
      "wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi"])},
      "thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
      "fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fr"])},
      "sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa"])}
    },
    "months": {
      "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januar"])},
      "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februar"])},
      "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["März"])},
      "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
      "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
      "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juni"])},
      "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juli"])},
      "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
      "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
      "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober"])},
      "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
      "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezember"])}
    }
  },
  "form-fields": {
    "opening-times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnungszeiten"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiltelefonnummer"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "please-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])}
  },
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über Uns"])},
    "car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeuge"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungen"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
  },
  "footer": {
    "widget-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressourcen"])},
    "widget-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über uns"])},
    "car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autos"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkmale"])},
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "privecy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGB"])}
  },
  "home-hero": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilkommen bei Roadstars, Ihrer erstklassigen Autovermietung in Berlin"])}
  },
  "home-about": {
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÜBER UNS"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entdecken Sie Roadstars"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielseitig und individuell, genau wie Ihre Bedürfnisse - unsere Auswahl an Fahrzeugen bringt Sie flexibel von A nach B. Die Roadstars Autovermietung mit Sitz in Berlin-Charlottenburg bietet Ihnen immer das richtige Fahrzeug für Ihren Anlass - wann und wie Sie es wünschen. Ob Kleinwagen, Mittelklasse oder Obere Mittelklasse, wir stehen Ihnen jederzeit beratend zur Seite, wenn es um die Wahl des richtigen Fahrzeugs geht."])}
  },
  "home-cars": {
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUR DIE BESTEN FAHRZEUGE"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Fahrzeugflotte"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bieten unseren Kunden die unglaublichsten Fahrgefühle. Deshalb haben wir auch die besten Autos in unserer Flotte."])}
  },
  "home-sponsor": {
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IN KOOPERATION MIT"])}
  },
  "home-features": {
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KUNDENZUFRIEDENHEIT IST UNSERE PRIORITÄT"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Leistungen"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uns ist der Komfort und die Sicherheit unserer Kunden wichtig. Deshalb bieten wir den besten Service, damit Sie sich wohlfühlen."])}
  },
  "home-contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnungszeiten"])},
    "hours-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MO-FR 10-18"])},
    "hours-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA 10-16"])}
  },
  "home-search-form": {
    "vehicle-class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Fahrzeugklasse"])},
    "pick-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholort"])},
    "request-botton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt anfragen"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit"])},
    "pickup-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholzeit"])},
    "return-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückgabezeit"])},
    "pickup-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholdatum"])},
    "return-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückgabedatum"])},
    "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholung"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückgabe"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservierung bestätigen"])},
    "button-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit bestätigen"])}
  },
  "home-booking-request-form": {
    "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um"])},
    "name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title *"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservierung anfragen"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung"])},
    "pickup-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholort"])},
    "pickup-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholdatum"])},
    "return-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückgabezeit"])},
    "vehicle-class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Fahrzeugklasse"])},
    "form-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Daten"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
    "date-of-birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenname"])},
    "mrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
    "mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])},
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname *"])},
    "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname *"])},
    "date-birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum *"])},
    "place-birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsort *"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse *"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße & Nr *"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt *"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl *"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land *"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiltelefonnummer *"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email *"])},
    "id-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Nr. *"])},
    "driving-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führerschein Nr. *"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt anfragen"])}
  },
  "button": {
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "cancle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "please-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte warten"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SENDEN"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])}
  },
  "legal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "managing-director": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsführer"])}
  },
  "profile": {
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])}
  },
  "privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeiner Hinweis und Pflichtangaben"])},
    "paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benennung der verantwortlichen Stelle"])},
    "paragraph-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:"])},
    "paragraph-4": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadstars GmbH"])},
      "road": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahmad El Noumeiri"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaiser-Friedrich-Straße 65"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10627 Berlin"])}
    },
    "paragraph-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der für die Verarbeitung Verantwortliche bestimmt allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o.ä.)."])},
    "paragraph-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widerruf Ihrer Einwilligung zur Datenverarbeitung"])},
    "paragraph-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Ein Widerruf Ihre bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf ist eine formlose Mitteilung per E-Mai ausreichend. Die Rechtmäßigkeit der Datenverarbeitung bleibt durch den Widerruf unberührt."])},
    "paragraph-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde"])},
    "paragraph-9": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als betroffene Person haben Sie das Recht, sich mit einer Beschwerde an die zuständig Aufsichtsbehörde. Die zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist de Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Unte folgendem Link finden Sie eine Liste der Datenschutzbeauftragten und deren Kontaktdate:"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/adressen_links-node.html"])},
      "link-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klick hier"])}
    },
    "paragraph-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recht auf Datenübertragbarkeit"])},
    "paragraph-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben das Recht, Daten, die wir aufgrund Ihrer Einwilligung oder in Erfüllung eines Vertrages automatisier verarbeiten, an zu übermitteln oder an Dritte aushändigen zu lassen. Die Daten werden in eine maschinenlesbaren Format zur Verfügung gestellt. Wenn Sie die direkte Übermittlung der Daten an eine anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist."])},
    "paragraph-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recht auf Auskunft, Berichtigung, Sperrung, Löschung"])},
    "paragraph-13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltlich Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger sowie den Zwec der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglic und auch zu weiteren Fragen zum Thema personenbezogenen Daten können Sie sich jederzeit über die i Impressum aufgeführten Kontaktmöglichkeiten an uns wenden."])},
    "paragraph-14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSL- oder TLS-Verschlüsselung"])},
    "paragraph-15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreibe senden, verwendet unsere Website eine unsere Website eine SSL- oder TLS-Verschlüsselung. Dies bedeutet, dass Daten, die Sie über diese Website übermitteln, nicht von Dritten gelesen werden können. Dritte. Eine verschlüsselte Verbindung erkennen Sie an der Adresszeile Ihres Browsers 'https://' und dem Schloss-Symbol in der Browserzeile. "])}
  },
  "terms": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Geschäftsbedingungen"])},
    "text-p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadstars GmbH überlässt dem Mieter das Fahrzeuggemäß den beschriebenen Mietbedingungen und den nachfolgenden Allgemeinen Geschäftsbedingungen, welche der Mieter anerkennt. Der Mietvertrag kommt durch schriftliche Bestätigung des Auftrages an den Mieter zustande. Wird das Fahrzeug über unsere Telefonhotline gebucht kommt der Vertrag zustande durch eine Bestätigung per Bestätigungs-E-Mail an den Mieter."])},
    "text-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I ALLGEMEINES"])},
    "text-p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a) Mit Inkrafttreten des Vertrages erkennt der Mieter an, dass er den Mietwagen mit vollständiger Ausrüstung einschließlich Werkzeug, Verbandskasten und Radiogerät, sowie ohne äußerlich erkennbare Beschädigungen übernommen hat (Abweichungen werden im Mietvertrag vor Ort festgehalten). Zudem erkennt der Mieter weiterhin an, dass ihm eine bestätigte Kopie der Wagenpapiere ordnungsgemäß übergeben worden sind."])},
    "text-p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b) Der Mieter kann bis 5 Tage vor Mietbeginn per E-Mail vom Vertrag zurücktreten, ohne Berechnung von Kosten. Bei einem Rücktritt nach diesem Zeitpunkt, der bis spätestens 24 Stunden vor Mietbeginn mitgeteilt wird, werden 50 % des Mietentgelts in Rechnung gestellt. Bei einem Rücktritt nach diesem Zeitpunkt wird, ebenso wie bei Nichtabholung des Fahrzeugs, der gesamte Mietbetrag in Rechnung gestellt. Anspruch auf Rückerstattung des Mietpreises besteht nur bei dem eintretenden durch den Vermieter unverschuldeten oder unvorhersehbaren Fall wie z.B. Krankheit. "])},
    "text-p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c)Der Mieter haftet für sämtliche Bußgelder, Gebühren und Strafen selbst. Für die Bearbeitung anfallender Gebührenbescheide wird eine Bearbeitungsgebühr in Höhe von EUR 9,55 je einzelnem Fall berechnet."])},
    "text-p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d)Der Vermieter ist berechtigt, das Fahrzeug bei erheblichen Verstößen des Mieters gegen Bestimmungen dieses Vertrages oder aus sonstigem wichtigem Grund vorzeitig zurückzufordern.  Der Mieter kann dies nur mittels einer unbestrittenen oder rechtskräftig festgestellten Forderung aufrechnen."])},
    "text-p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e)Der Mieter erklärt, dass er neben anderen Risiken das Fahrzeug nicht unter Drogen- und Alkoholbeeinflussung führt. Es ist untersagt das Fahrzeug für motorsportliche Veranstaltungen & Wettkämpfe jeder Art zu benutzen (s. §VI)."])},
    "text-p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f)Sollten dem Vermieter Unstimmigkeiten in der Person des Mieters vorliegen oder sollte er erkennen, dass der Mieter Voraussetzungen dieser Bestimmungen nicht erfüllen kann, ist der Vermieter berechtigt vom Vertrag zurückzutreten. Eine etwaige Entschädigung steht dem Mieter hierbei nicht zu."])},
    "text-p9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["g)Unabhängig von ausdrücklich schriftlichen Vereinbarungen stellen alle Angaben des Mieters im Mietvertrag einen wesentlichen Bestandteil dieses Vertrages dar. Insbesondere erklärt der Mieter mit seiner Unterschrift verbindlich, dass er zur Zahlung des Mietpreises fähig ist."])},
    "text-p10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h) Sollte der Vermieter die gebuchte Fahrzeuggruppe nicht zur Verfügung stellen können, behält sich der Vermieter das Recht vor, ein Alternativfahrzeug bereit zu stellen oder vom Vertrag zurückzutreten und den angefallenen Mietzins zu erstatten."])},
    "text-p11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I IMIETPREIS UND KAUTION"])},
    "text-p12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Mietpreis und Versicherungsschutz ergibt sich aus der jeweils gültigen Preisliste des Vermieters. Der Mietpreis zzgl. etwaiger Kaution ist im Voraus fällig. Die Zahlungsart wird hierbei vom Vermieter vorgegeben. Wird mittels Kreditkarte bezahlt, ist der Vermieter berechtigt, auch eventuell aufgetretene Schäden bzw. Selbstbeteiligungen und Kautionen über diese abzurechnen. Bei Zahlung per EC Cash erklärt sich der Mieter damit einverstanden, dass Nachforderungen aus dem Vertragsverhältnis per Lastschrift eingezogen werden dürfen. Sollte während der Mietzeit ein Defekt auftreten, entsteht daraus kein Anspruch des Mieters auf Minderung des Mietpreises. Der Vermieter ist bemüht, dem Mieter umgehend ein Ersatzfahrzeug bereitzustellen."])},
    "text-p13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["III FÜHRUNGSBERECHTIGTE"])},
    "text-p14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Fahrzeug darf nur vom Mieter und den im Mietvertrag angegebenen Fahrern geführt werden. Alle Bestimmungen dieses Vertrages gegenüber dem Mieter gelten auch für den jeweils berechtigten Fahrer (siehe §IV). Der Mieter ist nicht berechtigt das Mietfahrzeug entgeltlich oder leihweise einer dritten Person zu überlassen, auch nicht zur kurzfristigen Nutzung; es sei denn es erfolgte eine schriftliche Vereinbarung im Mietvertrag."])},
    "text-p15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IV FAHRZEUGNUTZUNG"])},
    "text-p16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a) Das Fahrzeug wird vom Vermieter mit einem Tankstand der mindestens Reserve (ca. 5 Liter) anzeigt übergeben. Der Mieter hat das Fahrzeug entsprechend dem Tankstand zurückzugeben, mit dem er das Fahrzeug übernommen hat. Bei einer Differenz des Tankinhalts bei Fahrzeugrücknahme wird eine Pauschale von EUR 8,00 pro Anzeigenbalken berechnet. Erfolgt eine Rückgabe mit Tankinhaltsplus wird diese nicht vergütet. Benzinkosten während der Vertragsdauer gehen zu Lasten des Mieters. Pro Miettag (24 Stunden) ist eine im Mietvertrag vermerkte Freikilometerpauschale enthalten. Weitere Frei-km können vor Anmietung gegen Berechnung einer Pauschale hinzugebucht werden. Darüber hinaus angefallene Mehrkilometer werden nach Rückgabe des Fahrzeuges zusätzlich mit EUR 0,18 pro Kilometer berechnet."])},
    "text-p17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b) Die Mietfahrzeuge werden regelmäßig gewartet. Wird während der Mietzeit eine Reparatur notwendig, um Betrieb oder Verkehrssicherheit des Fahrzeuges zu gewährleisten, ist in jedem Fall der Vermieter zu kontaktieren und dessen Einwilligung einzuholen. Die Reparaturkosten trägt der Vermieter gegen Vorlage der entsprechenden Rechnung, sofern nicht der Mieter für den Schaden haftbar zu machen ist (siehe §V c)."])},
    "text-p18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c) Es ist dem Mieter untersagt, das Fahrzeug zu motorsportlichen Veranstaltungen, Wettkämpfen, Testzwecken, zur Weitervermietung, zur gewerblichen Personenbeförderung sowie sonstigen rechtswidrigen Zwecken zu nutzen. Fahrten außerhalb des Bundesgebietes bedürfen der schriftlichen Zustimmung des Vermieters."])},
    "text-p19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V VERSICHERUNG UND HAFTUNG"])},
    "text-p20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a) Die Mietfahrzeuge sind mit einer maximalen Deckungssumme für Personen- und Sachschäden mit einer Gesamthöhe von EUR 8 Millionen versichert. Der Haftpflichtversicherungsschutz umfasst nur Sach-, Personen-, und reine Vermögensschäden Dritter, die beim Betrieb des Fahrzeugs entstehen, nicht jedoch Schäden am gemieteten Fahrzeug und an Sachen, die der Mieter oder Dritte in das Fahrzeug eingebracht haben. Für den Versicherungsschutz und die Leistungen gelten die Bestimmungen des Versicherers des Vermieters entsprechend, soweit diese nicht bereits unmittelbar aus dem Vertrag gelten."])},
    "text-p21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b) Für die Fahrzeuge besteht ohne Einschluss kein Fahrzeugversicherungsschutz in Form einer Teilkasko- oder Vollversicherung, mit der unmittelbare Schäden, d.h. Beschädigung, Zerstörung oder der Verlust des gemieteten Kraftfahrzeuges oder Teilen hiervon gedeckt sind. Dieser kann gegen Einschluss einer Vollkaskoversicherung mit einer jeweils vereinbarten Selbstbeteiligung eingeschlossen werden."])},
    "text-p22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c) Der Mieter haftet für sämtliche während der Dauer des Mietvertrages entstandene Schäden am oder im Fahrzeug oder den Verlust des Fahrzeuges einschließlich Fahrzeugteilen und Zubehör, ferner für die  Kosten der Bergung und des Abschleppens des Fahrzeuges, die Kosten der Schadensfeststellung, Kosten für Sachverständige, Mietausfall, Minderwert, Kosten von Reparatur oder Ersatz der Werbefolie sowie sonstige Kosten, soweit diese nicht von einem dem Vermieter bekannten Dritten zu vertreten und zu tragen sind."])},
    "text-p23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgende Schadensereignisses sind hierbei beispielhaft zu nennen:"])},
    "text-p24": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zerstörung oder Beschädigung des Fahrzeuges durch selbstverursachten Unfall Brand und Explosion"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diebstahl, Raub oder unbefugter Gebrauch"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unmittelbares Einwirken von Sturm, Hagel, Blitzschlag oder Überschwemmung äußerliches Einwirken von Tieren und Haarwild"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schneelawinen"])},
      "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruchschäden an der Verglasung"])},
      "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand- oder Schmorschäden an der Verkabelung Vandalismus-Schäden"])},
      "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durch Marderbiss unmittelbar verursachte Beschädigung"])},
      "p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch den zusätzlichen Abschluss einer Vollkaskoversicherung kann man sich gegen diese Schäden, mit einem Selbstbehalt von EUR 750,00 absichern. Gegen weitere Zahlung kann dieser Selbstbehalt bis auf EUR 350,00 verringert werden. Hierbei ist §V d) Diebstahlversicherung zu beachten."])}
    },
    "text-p25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiervon nicht erfasst sind:"])},
    "text-p26": {
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schäden an Fahrzeug und Reifen, die durch Bedienungsfehler entstehen Schäden an der Werbefolie"])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten der internen Bearbeitung eines Schadenereignisses"])},
      "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten für die Bearbeitung von Bußgeldern, Gebühren und Strafen (siehe § I f)"])},
      "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten für Behördeneinsätze, Bergungs- und Abschleppkosten, Sachverständigenkosten"])}
    },
    "text-p27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d) Bei Diebstahl des Fahrzeuges und wenn der Kunde die bei Anmietung erhaltenen Schlüssel nicht zurückgeben kann gilt die volle Haftungssumme in Form des Wertes des Fahrzeuges als vereinbart, ebenso wenn dem Mieter eine Beteiligung am Diebstahl nachgewiesen werden kann. Außerdem hat der Mieter die Pflicht sofort der Vermieter telefonisch oder telegraphisch zu unterrichten. Gegnerische Ansprüche dürfen nicht, weder mündlich noch schriftlich, anerkannt werden. Bei Fahrzeugrückgabe hat der Mieter alle Schäden, Betriebsstörungen, sowie Unfallschäden dem Vermieter unaufgefordert mitzuteilen. Überlässt der Mieter den Mietwagen an eine im Mietvertrag nicht aufgeführte dritte Person, so haften der Mieter und der Dritte im Falle einer Beschädigung des Mietwagens als Gesamtschuldner unbeschränkt."])},
    "text-p28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIII HAFTUNG DES VERMIETERS"])},
    "text-p29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schadenersatzansprüche des Mieters gegenüber dem Vermieter aus dem Mietvertrag, gleich aus welchem Grunde, sind ausgeschlossen, es sei denn, der Schaden beruht auf einer grob fahrlässigen Vertragsverletzung des Vermieters bzw. es trifft § I b.2 ein."])},
    "text-p30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IX FAHRZEUGRÜCKGABE"])},
    "text-p31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Mieter ist verpflichtet das Fahrzeug bei Ablauf der Mietzeit dem Vermieter am vereinbarten Ort zurückzugeben. Wir empfehlen bis spätestens eine halbe Stunde vor Rückgabetermin am vereinbarten Ort zu erscheinen. Die Rückgabe kann nur während der Geschäftszeiten erfolgen, sofern keine andere Vereinbarung getroffen wurde. Fahrzeugrückgaben müssen bis spätestens 15 Minuten vor Geschäftsschluss getätigt werden. Das Fahrzeug ist zum vereinbarten Zeitpunkt, spätestens aber 30 Minuten nach diesem Zeitpunkt, zurückzugeben. Bei Rückgaben, die 31 Minuten oder später erfolgen, wird ein Zusatztag berechnet. Wird das Fahrzeug ohne Vereinbarung weitergefahren wird darüber hinaus eine Gebühr von EUR 50,- für jeden weiteren nicht vereinbarten Tag berechnet. Das Fahrzeug ist in ordnungsgemäßen Zustand zurückzugeben. Dies bedeutet insbesondere, dass das Fahrzeug - soweit erforderlich - innen und außen zu reinigen und in den Zustand bei Übergabe des Fahrzeuges zu versetzen ist. Das Fahrzeug ist mit mindestens dem bei Fahrzeugübergabe vereinbarten Tankstand zurückzugeben. Wird die Selbstreinigung nicht durch den Mieter vorgenommen, erhebt der Vermieter eine Gebühr von EUR 15,00 an Reinigungskosten.  Schäden am Fahrzeug sind unaufgefordert mitzuteilen. Übermäßiger Verschleiß, sowie Schäden durch übermäßige Fahrzeugbeanspruchung (z.B. Kupplung oder Reifen) gehen zu Lasten des Mieters."])},
    "text-p32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X NICHTERFÜLLUNG"])},
    "text-p33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Vermieter haftet nicht bei Nichterfüllung die auf technische Defekte oder Verunfallen des Fahrzeuges beruht. Gerichtsstand und Erfüllungsort ist der Sitz des Vermieters."])},
    "text-p34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATENSPEICHERBEDINGUNGEN"])},
    "text-p35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a) Die personenbezogenen Daten der Nutzer werden unbeschadet der Dauer des Vertrags (einschließlich etwaiger Verlängerungen) und für die 10 Jahre nach dessen Laufzeit, Kündigung oder Rücktritt aufbewahrt in den Fällen, in denen für Streitigkeiten, Ersuchen der zuständigen Behörden oder gemäß den geltenden Rechtsvorschriften eine Aufbewahrung für einen späteren Zeitraum erforderlich ist;"])},
    "text-p36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b) Für Marketingzwecke im Zusammenhang mit dem Versand von Werbematerial und der Verwendung im Rahmen von Analysen und kommerziellen Studien sowie Konsumgewohnheiten werden die personenbezogenen Daten der Nutzer für die Dauer des Vertrags und für einen Zeitraum von 5 Jahren danach aufbewahrt."])},
    "text-p37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XI RECHTE DER BENUTZER IN BEZUG AUF IHRE PERSÖNLICHEN DATEN"])},
    "text-p38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können jederzeit folgende Rechte ausüben:"])},
    "text-p39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fordern Sie weitere Informationen in Bezug auf den Inhalt dieser Informationen an, greifen Sie auf personenbezogene Daten zu, erhalten Sie deren Berichtigung oder Löschung oder die Einschränkung der ihn betreffenden Verarbeitung (in den gesetzlich vorgesehenen Fällen), lehnen Sie die Verarbeitung ab (in die gesetzlich vorgesehenen Fälle); Datenübertragbarkeit (in den gesetzlich vorgesehenen Fällen); gegebenenfalls Widerruf der Einwilligung. Der Widerruf der Einwilligung berührt nicht die Rechtmäßigkeit der Verarbeitung auf der Grundlage der vor dem Widerruf erteilten Einwilligung. Schlagen Sie eine Beschwerde bei der Aufsichtsbehörde (Datenschutzgarant) vor. In Bezug auf die Verarbeitung der ihn betreffenden personenbezogenen Daten, wie oben beschrieben, drückt der Kunde seine Zustimmung gemäß und für die Zwecke des Gesetzes frei aus. Wenn eine Bestimmung dieses Mietvertrags nichtig ist, entscheidet diese Nichtigkeit nicht über die Ungültigkeit der anderen Bestimmungen dieses Mietvertrags. Wenn der Kunde beschließt, in einer anderen Währung als der zu zahlen, in der die Mietkosten angegeben wurden, wird der Gegenwert anhand des von der Bundesbank veröffentlichten Wechselkurses zuzüglich 4% als Erstattung der Bankgebühren und Provisionen sowie des Wechselkursrisikos berechnet. Zur Verarbeitung personenbezogener Daten für den Versand von Werbematerial und deren Verwendung im Rahmen von Analysen und kommerziellen Studien sowie von Konsumgewohnheiten gemäß den Informationen in Artikel 10 (Datenschutz) Punkt 2 dieses Vertrags. Nach dem Lesen der Datenschutzrichtlinie und der Allgemeinen Mietbedingungen erklärt der Kunde, alle Klauseln ausdrücklich zu genehmigen."])}
  },
  "text": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrag"])},
    "created-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wurde erfolgreich abgeschlossen"])},
    "completed-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wurde erfolgreich abgeschlossen"])},
    "rese-success-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservierung anfragen"])},
    "rese-success-text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihre Anfrage!"])},
    "rese-success-text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Mitarbeiter von Roadstars werden sich so schnell wie möglich bei Ihnen melden!"])},
    "no-data-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine!"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "go-to-home-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehen Sie zur Startseite"])},
    "your-reservation-is-successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Reservierung ist erfolgreich"])},
    "your-reservation-is-declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Reservierung wurde abgelehnt"])},
    "your-reservation-is-unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Reservierung ist fehlgeschlagen"])},
    "success-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage erfolgreich gesendet"])},
    "error-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas falsch, bitte versuchen Sie es erneut"])}
  }
}