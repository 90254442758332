<script setup>
import { reactive, ref } from 'vue'
import { api } from "@/use/useAxios"
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

const errors = ref([])
const form= reactive({
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  messages: '',
})
const initForm= reactive({
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  messages: '',
})
const message= reactive({
  title: null,
  type: null,
})

const reset = () =>{
  Object.assign(form, initForm)
}

const submitForm = async () => {
  try {
    const data = await api.post('contact-store', form)
    if (data) {
      message.title = t("text.success-text")
      message.type = 'success'
      reset()
      setTimeout(() => {
        message.title = ''
        message.type = ''
      }, 6000)
    }
  } catch (error) {
    if (error.response.data) {
      message.title = t("text.error-text")
      message.type = 'error'
    }
  }
}

const handleSubmit = ()=> {
  errors.value = []
  if(!form.first_name) {
    errors.value.push('firstName')
  }
  if(!form.last_name) {
    errors.value.push('lastName')
  }
  if(!form.email) {
    errors.value.push('email')
  }
  if(!form.phone_number) {
    errors.value.push('phone')
  }
  if(!form.messages) {
    errors.value.push('messages')
  }
  if(!errors.value.length) {
    submitForm()
  }
}
</script>
<template>
  <div class="container p-0">
    <form @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col">
          <input v-model="form.first_name" class="contact_input" :class="{ error: errors.includes('firstName') }" type="text" :placeholder="t('profile.first-name')" />
        </div>
        <div class="col">
          <input v-model="form.last_name" class="contact_input" :class="{ error: errors.includes('lastName') }" type="text" :placeholder="t('profile.last-name')" />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <input v-model="form.email" class="contact_input" :class="{ error: errors.includes('email') }" type="text" :placeholder="t('form-fields.email')" />
        </div>
        <div class="col">
          <input v-model="form.phone_number" class="contact_input" :class="{ error: errors.includes('phone') }" type="text" :placeholder="t('form-fields.phone')" />
        </div>
      </div>
      <div class="mt-4">
        <textarea v-model="form.messages" style="resize: none" class="textarea_input" :class="{ error: errors.includes('messages') }" rows="8" :placeholder="t('form-fields.message')"></textarea>
      </div>
      <div class="d-grid mt-3">
        <button type="submit" class="contact_btn">
          {{ t('button.submit') }}
        </button>
      </div>
    </form>
    <p v-if="message.title" class="message" :class="`${message.type}__message`">
      {{ message.title }}
    </p>
  </div>
</template>

<style scoped>
.contact_input {
  width: 100%;
  height: 2.75rem;
  border: none;
  outline: none;
  padding: 0rem 0.8rem;
  background: #f0f0f0;
  border-radius: 0.25rem;
}
.textarea_input {
  width: 100%;
  border: none;
  outline: none;
  padding: 1rem 0.8rem;
  background: #f0f0f0;
  border-radius: 0.25rem;
}
::placeholder {
  color: #4f4444;
  font-size: 1rem;
}
.contact_btn {
  outline: none;
  box-shadow: none;
  border-radius: 0.25rem;
  border: none;
  background-color: var(--color-dark);
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
  padding: 0.6rem;
  transition: all 0.5s ease;
}

.message {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  font-weight: 500; 
}
.success__message {
  color: green;
}
.error__message {
  color: var(--color-primary);
}

.error {
  border-color: var(--color-error) !important;
  caret-color: var(--color-error);
  /* background-color: rgba(255, 82, 82, 0.18); */
}
.contact_btn:hover {
  background-color: var(--color-primary);
}
@media (max-width: 768px) {
}
@media (max-width: 576px) {
  ::placeholder {
    font-size: 0.938rem;
  }
}
</style>
