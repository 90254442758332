<script setup>
import SectionHeader from "../common/SectionHeader.vue"
import { useI18n } from 'vue-i18n'
const { t } = useI18n({ useScope: 'global' })
</script>

<template>
  <div id="sponsor" class="sponsor-section">
    <div class="container text-center">
      <SectionHeader :subtitle="t('home-sponsor.sub-title')" />
        <img class="mt-2" src="@/assets/images/sponsors/Maxima.png" alt="" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sponsor-section {
  margin-bottom: 3.5rem;
  background-color: #000;
  padding: 3rem 0;
}

img {
    width: 40%;
    height: auto;

    @include responsive-max("sm") {
      width: 80%;
      margin: 0 auto;
    }
  }
</style>