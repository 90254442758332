import { SetupCalendar } from 'v-calendar'
import Toast from "vue-toastification"
import AppLink from '@/components/base/AppLink.vue'
import InputControl from "@/components/dashboard/form/InputControl.vue"
import SelectControl from "@/components/dashboard/form/SelectControl.vue"
import moment from 'moment'

import 'v-calendar/dist/style.css'
import "vue-toastification/dist/index.css"

const toastConfig = {
  timeout: 1000,
  containerClassName: 'toast__container',
  hideProgressBar: true
}

export default app => {
  app.use(SetupCalendar, {})
  app.use(Toast, toastConfig)

  app.component('app-link', AppLink)
  app.component('InputControl', InputControl)
  app.component('SelectControl', SelectControl)

  // Global Properties
  app.config.globalProperties.moment = moment
}
